import styled from 'styled-components'

const PostsList = styled.ol`
list-style-type: none;
  max-width: 70ch;
  padding: 0;
  li {
    a {
      padding: 0.5rem 1rem;
      position: relative;
      transform: scale(1);
      transition: transform 0.3s ease-out;
      will-change: transform;
      &:hover,
      &:focus {
        box-shadow: 0px 1px 3px 1px #0000001f, 
                    0px 1px 4px 2px #6f3f981c;
        transform: scale(1.05);
        &:before {
          opacity: 0.2;
        }
      }
      &:before {
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease-out;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }
  li + li {
    margin-top: 2rem;
  }
  a {
    background-image: unset;
    display: block
  }
  p {
    margin-bottom: 0;
  }
  .post-title {
    font-family: ${props => props.theme.serif};
    font-size: 1.4rem;
    margin: 0;
  }
`

export default PostsList