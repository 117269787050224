import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Link from 'gatsby-link'

import Layout from '../components/layout'

import ContentWrapper from '../styles/contentWrapper'
import OffsetWrapper from '../styles/offsetWrapper'
import TitleWrapper from '../styles/titleWrapper'
import PostsList from '../styles/postsList'

const TagIndex = ({ pageContext }) => {

  const posts = pageContext.posts.map(post => {
    return (
      <li key={post.contentful_id}>
        <Link to={`/blog/${post.slug}`}>
          <p className="post-title">{post.title}</p>
          {documentToReactComponents(post.excerpt.json )}
        </Link>
      </li>
    )
  })

  return (
    <Layout>
      <ContentWrapper>
        <TitleWrapper>
          <h1>{pageContext.tagName}</h1>
        </TitleWrapper>
        <OffsetWrapper>
          <PostsList>
            {posts}
          </PostsList>
        </OffsetWrapper>
      </ContentWrapper>
    </Layout>
  )
}

export default TagIndex